import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import HowThisWorkImageIcon from "../icons/howThisWorkImage";
import ScrollToTop from "@components/shared/scrollToTop";
const steps = [
    {
        id: 1,
        text: "Fill out our simple form. We'll use your answers to find the best car accident lawyer for you.",
    },
    {
        id: 2,
        text: "Our lawyer will contact you to schedule a free, no-obligation consultation. They'll ask questions to establish your case throughout this call.",
    },
    {
        id: 3,
        text: "Your attorney will submit your case and fight for the best compensation for your injuries.",
    },
];
export default function HowThisWork(): ReactElement {
    return (
        <>
            <div
                className={`flex ${styles["howThisWork"]} flex-col md:flex-row max-w-7xl mx-auto items-center`}
            >
                <div className={`${styles["howThisWorkListWrapper"]}`}>
                    <h2
                        className={`bold mb-12 text-3xl text-center md:text-left md:text-4xl text-[#ac302a]`}
                    >
                        How Does This Work?
                    </h2>
                    <div>
                        {steps.map((step) => (
                            <div
                                className={`flex justify-between mb-12`}
                                key={step.id}
                            >
                                <div
                                    className={`flex justify-center items-center bold ${styles["stepNum"]}`}
                                >
                                    {step.id}
                                </div>
                                <div
                                    className={`${styles["stepText"]} text-base md:text-xl`}
                                >
                                    {step.text}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={` ${styles["howThisWorkImage"]}`}>
                    <HowThisWorkImageIcon />
                </div>
            </div>
            <div className="flex justify-center items-center mb-8  py-8 md:py-8">
                <ScrollToTop
                    colors={{
                        bg: "#be1e20",
                        text: "white",
                    }}
                    className="rounded"
                >
                    Get My Free Lawsuit Case Review
                </ScrollToTop>
            </div>
        </>
    );
}
